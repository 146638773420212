import { isValidPhone, validNameRegex } from '@finn/ua-auth';
import { config, Region } from '@finn/ui-utils';
import axios from 'axios';
import * as Yup from 'yup';

export type JobAutoFormIntegromatPayload = {
  first_name: string;
  last_name: string;
  company_name: string;
  email: string;
  phone_number: string;
  message: string;
  number_of_employees: string;
  fleet_size: string;
};

export const initialValues = {
  email: null,
  firstName: null,
  lastName: null,
  company: null,
  phoneNumber: null,
  message: '',
  numberOfEmployees: null,
  fleetSize: null,
  gdpr: false,
};

export const createValidationSchema = ({ region }: Region = {}) =>
  Yup.object({
    email: Yup.string().email('yup.email').nullable().required('yup.required'),
    phoneNumber: isValidPhone('phoneNumber', region),
    company: Yup.string().nullable().required('yup.required'),
    firstName: Yup.string()
      .matches(validNameRegex, {
        message: 'yup.string',
        excludeEmptyString: true,
      })
      .nullable()
      .required('yup.required'),
    lastName: Yup.string()
      .matches(validNameRegex, {
        message: 'yup.string',
        excludeEmptyString: true,
      })
      .nullable()
      .required('yup.required'),
    numberOfEmployees: Yup.number()
      .positive('yup.number-positive')
      .typeError('yup.number')
      .nullable()
      .required('yup.required'),
    fleetSize: Yup.number()
      .positive('yup.number-positive')
      .nullable()
      .required('yup.required'),
    message: Yup.string().required('yup.required'),
    gdpr: Yup.bool().oneOf([true]),
  });

export const validationSchema = createValidationSchema();

export type FormValues = Yup.InferType<typeof validationSchema>;

export const formatFormDataToIntegromatPayload = (
  values: FormValues
): JobAutoFormIntegromatPayload => ({
  first_name: values.firstName || '',
  last_name: values.lastName || '',
  company_name: values.company || '',
  number_of_employees: values.numberOfEmployees.toString() || '',
  email: values.email,
  phone_number: values.phoneNumber,
  fleet_size: values.fleetSize.toString() || '',
  message: values.message || '',
});

export const submitJobAutoContactForm = async (
  payload: JobAutoFormIntegromatPayload
) => {
  const { data, status } = await axios.post(config.INTEGROMAT_JOBAUTO_FORM, {
    params: payload,
  });

  return { data, status };
};
