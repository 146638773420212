import { showToast } from '@finn/design-system/atoms/toast';
import { B2BFormTypeCosmicData } from '@finn/ui-cosmic';
import {
  triggerB2BFormSubmissionEvent,
  triggerB2BLeadGenFormSubmissionEvent,
} from '@finn/ui-modules';
import {
  CookieKeys,
  formatPhoneNumber,
  getB2BFormSource,
  getClientCookie,
  getSession,
  identifyAnonymousUserEvent,
  identifyEvent,
  useCurrentLocale,
} from '@finn/ui-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { submitB2bContactForm as integromatSubmit } from '~/services/b2b';
import { UIBaseCosmicObject } from '~/types/UIModules';

import Form from '../Form';
import {
  ContactValues,
  createValidationSchema,
  initialValues,
  toIntegromate,
} from './config';

type Props = {
  cosmicData: UIBaseCosmicObject<B2BFormTypeCosmicData>;
};

export const FormWrapper = ({ cosmicData }: Props) => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const { region } = useCurrentLocale();
  const router = useRouter();
  const isB2BPage = router.asPath?.includes('/b2b');

  const form = useForm<ContactValues>({
    mode: 'onChange',
    resolver: yupResolver(createValidationSchema({ region })),
    defaultValues: initialValues,
  });

  const handleSubmit = async (values: ContactValues) => {
    const contactData = {
      ...values,
      phoneNumber: formatPhoneNumber(values.phoneNumber, region),
      b2bMultiplier: getClientCookie(CookieKeys.B2B_MULTIPLIER) ?? '',
      utmSource: getB2BFormSource('B2B Form'),
      referrerCode: getClientCookie(CookieKeys.B2B_REFERRER_CODE) ?? '',
      b2bUrl: router.asPath,
    };
    setLoading(true);
    const integromatResponse = await integromatSubmit(
      toIntegromate(contactData),
      region
    );
    setLoading(false);
    if (integromatResponse.status === 200) {
      if (isB2BPage) {
        triggerB2BFormSubmissionEvent(values.fleetSize, values.email);
      } else {
        triggerB2BLeadGenFormSubmissionEvent(values.fleetSize, values.email);
      }
      const session = await getSession();
      if (session && session.user.hs_contact_id) {
        identifyEvent(String(session.user.hs_contact_id), {
          companyName: values.company,
          firstName: values.firstName,
          lastName: values.lastName,
          loggedIn: !!session,
        });
      } else {
        identifyAnonymousUserEvent({
          companyName: values.company,
          firstName: values.firstName,
          lastName: values.lastName,
          loggedIn: !!session,
        });
      }
      setSuccessMessage(cosmicData.metadata.submit_success_message);
      form.reset();
      setTimeout(() => {
        setSuccessMessage(null);
      }, 2000);
    }
  };

  useEffect(() => {
    if (successMessage) {
      showToast({
        status: 'success',
        title: successMessage,
      });
    }
  }, [successMessage]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Form isB2B form={form} loading={loading} cosmicData={cosmicData} />
      </form>
    </FormProvider>
  );
};
