import { showToast } from '@finn/design-system/atoms/toast';
import { JobAutoFormTypeCosmicData } from '@finn/ui-cosmic';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ui-modules';
import { formatPhoneNumber, useCurrentLocale } from '@finn/ui-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { UIBaseCosmicObject } from '~/types/UIModules';

import {
  createValidationSchema,
  formatFormDataToIntegromatPayload,
  FormValues,
  initialValues,
  submitJobAutoContactForm,
} from './data';
import Form from './Form';

type Props = {
  cosmicData: UIBaseCosmicObject<JobAutoFormTypeCosmicData>;
};

export const FormWrapper = ({ cosmicData }: Props) => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const { region } = useCurrentLocale();
  const router = useRouter();

  const form = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(createValidationSchema({ region })),
    defaultValues: initialValues,
  });

  const handleSubmit = async (values: FormValues) => {
    const formData = {
      ...values,
      phoneNumber: formatPhoneNumber(values.phoneNumber, region),
    };

    setLoading(true);
    try {
      const { status } = await submitJobAutoContactForm(
        formatFormDataToIntegromatPayload(formData)
      );
      if (status >= 200 && status < 300) {
        setSuccessMessage(cosmicData.metadata.submit_success_message);
        setLoading(false);
        interactionTrackingEvent(TrackingEventName.JOB_AUTO_FORM_SUBMITTED, {
          url: router.pathname,
        });
        form.reset();
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (successMessage) {
      showToast({
        status: 'success',
        title: successMessage,
      });
    }
  }, [successMessage]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Form form={form} loading={loading} cosmicData={cosmicData} />
      </form>
    </FormProvider>
  );
};
